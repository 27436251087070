// libs
import get from 'lodash/get';
import transformWidgets from '@nykaa/deals-transformer';

// helpers
import { getDeviceType } from 'shared/components/ResponsiveLoader/helpers';

// API
import { getLandingPageData } from 'shared/api/landingpage';

// Redux Store
import { ActionTypes } from './actions';

// helpers
import { getLandingPageExperiments } from './helpers';

// types
import { IHeadersObject } from 'global';

// constants
import { RENDER_MODE } from 'shared/constants';

const getTransformedWidgets = (data: Record<string, any>) => {
  const tranformedData = transformWidgets(
    get(data, 'result', []),
    getDeviceType() === RENDER_MODE.DESKTOP
  );

  return [get(tranformedData, 'widgets', []), 'v2'];
};

export const fetchHomepageData = (
  headers: IHeadersObject,
  pageName: string,
  shouldTransformForDealsPlatform: boolean = false
) => {
  return (dispatch: any, getState: () => any) => {
    dispatch({
      type: ActionTypes.FETCH_LANDINGPAGE_DATA_PROGRESS,
    });

    const state = getState();

    // get the valid experiments segment for given pageName
    const experiments = getLandingPageExperiments({ pageName, state });

    return getLandingPageData({ headers, experiments, pageName })
      .then((data) => {
        if (shouldTransformForDealsPlatform) {
          const [tranformedData, renderedVersion] = getTransformedWidgets(data);

          dispatch({
            type: ActionTypes.FETCH_LANDINGPAGE_DATA_SUCCESS,
            payload: { data: tranformedData, renderedVersion },
          });
          return tranformedData;
        } else {
          dispatch({
            type: ActionTypes.FETCH_LANDINGPAGE_DATA_SUCCESS,
            payload: { data: get(data, 'result', []), renderedVersion: 'v1' },
          });
          return data;
        }
      })
      .catch((err) => {
        if (err.status === 404) {
          dispatch({
            type: ActionTypes.FETCH_LANDINGPAGE_DATA_NOT_FAILED,
          });
        } else {
          dispatch({
            type: ActionTypes.FETCH_LANDINGPAGE_DATA_FAILED,
            payload: { statusCode: err?.status },
          });
        }

        return Promise.reject(err);
      });
  };
};

export const fetchLandingpageData = (
  headers: IHeadersObject,
  pageName: string,
  shouldTransformForDealsPlatform: boolean = false
) => {
  return (dispatch: any, getState: () => any) => {
    dispatch({
      type: ActionTypes.FETCH_LANDINGPAGE_DATA_PROGRESS,
    });

    const state = getState();

    // get the valid experiments segment for given pageName
    const experiments = getLandingPageExperiments({ pageName, state });

    return getLandingPageData({ headers, pageName, experiments })
      .then((data) => {
        if (shouldTransformForDealsPlatform) {
          const [tranformedData, renderedVersion] = getTransformedWidgets(data);
          dispatch({
            type: ActionTypes.FETCH_LANDINGPAGE_DATA_SUCCESS,
            payload: { data: tranformedData, renderedVersion },
          });
          return tranformedData;
        } else {
          dispatch({
            type: ActionTypes.FETCH_LANDINGPAGE_DATA_SUCCESS,
            payload: { data: get(data, 'result', []), renderedVersion: 'v1' },
          });
          return data;
        }
      })
      .catch((err) => {
        if (err.status === 404) {
          dispatch({
            type: ActionTypes.FETCH_LANDINGPAGE_DATA_NOT_FAILED,
          });
        } else {
          dispatch({
            type: ActionTypes.FETCH_LANDINGPAGE_DATA_FAILED,
            payload: { statusCode: err?.status },
          });
        }

        return Promise.reject(err);
      });
  };
};
