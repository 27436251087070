// libs
import { styled } from '@nykaa/ui-components';

// constants
import { MWEB_HEADER_ZINDEX } from 'shared/styles/base';

// mixin
import { resetButton } from 'shared/styles/mixin';
import { fadeIn } from 'shared/styles/animations/fadeIn';

// components
import RippleIt from 'shared/components/RippleIt';
import IOElement from 'shared/components/IOElement';
import ArrowLeft from '@nykaa/ui-components/Icons/arrow-left';

export const ScrollToTopButton = styled(RippleIt)`
  ${fadeIn}
  ${resetButton}

    border: none;
  position: fixed;
  bottom: 75px;
  right: 24px;
  z-index: ${MWEB_HEADER_ZINDEX - 1};
  background-color: #fff;
  height: 60px;
  width: 60px;
  box-shadow: ${({ theme }) => theme.elevations.shadow100};
  cursor: pointer;

  @media (max-width: 768px) {
    height: 40px;
    width: 40px;
  }

  .icon {
    display: block;
    font-size: 20px;
    transform: rotate(90deg);
  }
`;

export const Observer = styled(IOElement)`
  position: absolute;
  left: 1px;
  top: 100px;
  height: 1px;
  width: 1px;
`;

export const ScrollToTopIcon = styled(ArrowLeft)`
  transform: rotate(90deg);
  justify-content: center;
`;
