// libs
import handleImpressionUtil from '@nykaa/utils/impressions';

// types
import { Impression, Event } from './types';

// constants
import { IMPRESSION_TYPE } from './constants';
import { TRACK_TYPE } from 'shared/helpers/analytics/constants';

// utils
import { getImpressionEventData, getClickEventData } from './utils';

// helpers
import { ApiHelperForDealsPlatform as ApiHelper } from 'shared/helpers/api';
import NFA from 'shared/helpers/analytics/NFAnalytics';
import logger from 'shared/logger';

export const syncImpressionsHandler = (
  impressions: Impression[],
  impressionType: IMPRESSION_TYPE = IMPRESSION_TYPE.BANNER
) => {
  if (impressions && impressions.length > 0) {
    impressions.map((impression: Impression) => {
      if (impression.event && impression.event.indexOf('click') === -1) {
        const event: Event = getImpressionEventData(impression, impressionType);

        NFA.trackImpression(event);
      }

      return;
    });
  }
};

export const asyncImpressionsHandler = async (
  impressions: Impression[],
  customerId: string = '',
  device: string = 'DESKTOP'
) => {
  if (impressions && impressions.length > 0) {
    const bannerEvents: Event[] = [];

    impressions.map((impression: Impression) => {
      if (impression.event && impression.event.indexOf('click') > -1) {
        bannerEvents.push(getClickEventData(impression));
      }
    });

    if (bannerEvents.length > 0)
      NFA.track({
        event: 'multipleEvents',
        type: TRACK_TYPE.EVENT,
        data: { retinaDataArray: bannerEvents },
      });

    // custom utility to handle impressions, currently not including analytics sdk
    handleImpressionUtil(impressions, customerId, device, logger, ApiHelper);
  }
};
