// libs
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// selectors
import { isLoggedIn } from 'shared/store/app/selectors';
import { getWishlistIds } from 'shared/store/wishlist/selectors';
import { addToWishlist, removeFromWishlist } from 'shared/store/wishlist/services';
import { setShowWishlistNudge } from 'shared/store/app/actions';

// context
import { useAuthModalWrapperContext } from 'shared/components/AuthModal/AuthModalWrapperContext';
import { useResponsiveContext } from 'shared/components/ResponsiveLoader/ResponsiveContext';

// base styles
import { BREAKPOINTS } from 'shared/styles/base';
import { TOASTER_TYPES, useToastContext } from 'shared/components/Toast';
import {
  ADDED_TO_WISHLIST_MESSAGE,
  REMOVED_FROM_WISHLIST_MESSAGE,
  ADD_PRODUCT_TO_WISHLIST_FAILED,
  REMOVE_PRODUCT_FROM_WISHLIST_FAILED,
} from 'shared/components/Wishlist/constants';

// analytics
import { trackWishlistClicked } from './analytics';

interface StoredProductRef {
  productId: string;
  trackingData: Record<string, any>;
  params: Record<string, any>;
}

const DEFAULT_STORED_PRODUCT_REF: StoredProductRef = {
  productId: '',
  trackingData: {},
  params: {},
};

export const useWishlistHandler = () => {
  const isUserLoggedIn = useSelector(isLoggedIn);
  const wishlistData = useSelector(getWishlistIds);
  const dispatch = useDispatch();
  const { showAuthModal, showBottomSheetAuth } = useAuthModalWrapperContext();
  const { deviceWidth } = useResponsiveContext();
  const isDesktop = deviceWidth > BREAKPOINTS.DESKTOP;
  const { showToast } = useToastContext();
  const storedProductIdForWishist = useRef<StoredProductRef>(DEFAULT_STORED_PRODUCT_REF);

  const addProductToWishlist = useCallback(
    (productId: string, trackingData: Record<string, any>, params: Record<string, any>) => {
      // reset stored wishlist details to avoid mulitple calls
      storedProductIdForWishist.current = DEFAULT_STORED_PRODUCT_REF;
      trackWishlistClicked(false, trackingData, params);
      dispatch(addToWishlist({ itemId: productId }))
        .then(() => {
          !isDesktop
            ? showToast({ type: TOASTER_TYPES.INFO, message: ADDED_TO_WISHLIST_MESSAGE })
            : dispatch(setShowWishlistNudge(1));
        })
        .catch(() => {
          showToast({ type: TOASTER_TYPES.ERROR, message: ADD_PRODUCT_TO_WISHLIST_FAILED });
        });
    },
    [dispatch, isDesktop, showToast]
  );

  const handleWishList = (
    params: Record<string, any>,
    isInWishList: boolean,
    trackingData: Record<string, any>
  ) => {
    if (!isUserLoggedIn) {
      // if user is guest, and clicked on wishlist, store the values and call the same in future after successful login
      storedProductIdForWishist.current = { productId: params.productId, trackingData, params };

      // show respective login module
      isDesktop ? showAuthModal() : showBottomSheetAuth();
      return;
    }

    if (!isInWishList) {
      addProductToWishlist(params.productId, trackingData, params);
      return;
    }

    trackWishlistClicked(true, trackingData, params);

    dispatch(removeFromWishlist({ itemId: params.productId }))
      .then(() => {
        showToast({ type: TOASTER_TYPES.INFO, message: REMOVED_FROM_WISHLIST_MESSAGE });
      })
      .catch(() => {
        showToast({ type: TOASTER_TYPES.ERROR, message: REMOVE_PRODUCT_FROM_WISHLIST_FAILED });
      });
  };

  useEffect(() => {
    if (isUserLoggedIn && storedProductIdForWishist.current.productId !== '') {
      addProductToWishlist(
        storedProductIdForWishist.current.productId,
        storedProductIdForWishist.current.trackingData,
        storedProductIdForWishist.current.params
      );
    }
  }, [isUserLoggedIn, addProductToWishlist]);

  return { wishlistData, handleWishList };
};

export default useWishlistHandler;
