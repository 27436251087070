// libs
import get from 'lodash/get';
import { EVENT_NAMES, EVENT_TYPES } from './constants';

import { ProductPropsBeauty } from './types';

export const transformProductsToBeautyInterface = (productItem: Listing.IProduct) => {
  const generatedTags = productItem.tag?.map((item) => item.title);
  return {
    brandName: productItem.title,
    buttonText: '',
    newButtonText: '',
    childId: productItem.id,
    discount: productItem.discount,
    dynamicTags: generatedTags,
    newTags: generatedTags,
    id: productItem.id,
    imageUrl: productItem.imageUrl,
    inStock: Boolean(productItem.isOutOfStock),
    mrp: productItem.price,
    name: productItem.subTitle,
    offersCount: productItem.offersCount,
    onlyWishlistButton: true,
    parentId: productItem.id,
    price: productItem.price,
    offerPrice: productItem.discountedPrice,
    primaryCategories: { l1: { name: '', id: '' } },
    productId: productItem.id,
    quantity: 1,
    rating: productItem.rating,
    ratingCount: productItem.ratingCount,
    slug: productItem.slug,
    type: productItem.type,
    title: productItem.title,
    sku: productItem.sku,
    variantCount: 1,
    variantType: '',
    isBackorder: false,
    tracking_metadata: productItem.tracking_metadata,
  } as ProductPropsBeauty;
};

export const getImpressionsProductData = (productData: Listing.IProduct) => {
  if (String(productData.type).toUpperCase() === 'TIPTILE') {
    return {};
  }

  return {
    event: 'product:impressions',
    product_name: get(productData, 'subTitle', ''),
    product_sku: get(productData, 'sku', ''),
    event_type: 'impression',
    product_mrp: get(productData, 'price', 0),
    product_position: get(productData, 'productIndex', 0),
    product_price: get(productData, 'price', 0),
    product_dp: get(productData, 'discountedPrice', 0),
    product_id: get(productData, 'id', ''),
    product_brand: get(productData, 'title', ''),
    product_tags: get(productData, 'tag', []).map((item) => item?.title),
    listing_position: get(productData, 'listingPosition', 0),
  };
};

export const getProductClickedData = (productData: Record<string, any>) => {
  return {
    ...productData,
    event: undefined,
    event_name: EVENT_NAMES.PRODUCT_CLICKED,
    event_type: EVENT_TYPES.CLICK,
  };
};
