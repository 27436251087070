// constants
import { DEFAULT_CURRENCY_CODE } from 'shared/constants';
import { WISHLIST_EVENTS } from 'shared/helpers/analytics/constants/wishlist';

// helpers
import { EVENT_NAMES, sendSingleEvent, EVENT_TYPES } from 'shared/helpers/utils/impressions';
import NFA from 'shared/helpers/analytics/NFAnalytics';

export const trackWishlistClicked = (
  isInWishlist: boolean,
  trackingData: Record<string, any>,
  params: Record<string, any>
) => {
  // retina tracking
  sendSingleEvent({
    ...trackingData,
    event_name: isInWishlist ? EVENT_NAMES.REMOVE_FROM_WISHLIST : EVENT_NAMES.ADD_TO_WISHLIST,
    event_type: EVENT_TYPES.CLICK,
  });

  // mixpanel tracking
  NFA.track({
    event: isInWishlist ? WISHLIST_EVENTS.REMOVE_FROM_WISHLIST : WISHLIST_EVENTS.ADD_TO_WISHLIST,
    data: {
      wishlistProductID: params?.productId,
      wishlistProductType: params?.type,
      wishlistProductName: params?.name,
      wishlistProductBrandName: params?.brandName,
      currency: DEFAULT_CURRENCY_CODE,
      wishlistProductMRP: params?.mrp,
      wishlistProductDiscountedPrice: params?.discountedPrice,
      wishlistProductDiscountPercent: params?.discountPercent,
      wishlistProductIsOutOfStock: !params?.inStock,
      wishlistIsProductOffersAvailable: false,
      wishlistInteractionLocation: `productWidget:${trackingData?.dn_page_type}:${trackingData?.dn_page_section}`,
    },
  });
};
