// types
import { Impression, Event } from './types';

// helpers
import NFA from 'shared/helpers/analytics/NFAnalytics';

// constants
import { IMPRESSION_TYPE, IMPRESSION, EVENT_TYPES, EVENT_NAMES } from './constants';
import { NLP_EVENTS } from 'shared/helpers/analytics/constants/nlp';

export const getCommonEventData = (impression: Impression) => {
  const event: Event = {
    page_name: impression.pageName || '',
    dn_page_type: impression.namespace0,
    dn_page_section: impression.namespace1,
    dn_tile_id: impression.namespace2,
    dn_inventory_position: impression.position1,
    dn_tile_position: impression.position2,
    dn_transaction_id: impression.transaction_id,
    dn_request_page_data: impression.pageData1 || '',
    dn_inventory_page_data: impression.pageData2 || '',
    dn_brand_ids: impression.brand_ids || [],
    hit_recorded_at: impression.timestamp || new Date().getTime() / 1000,
    dn_tile_lang: impression.lang || 'en',
    dn_wtype: impression.wtype,
    dn_wtype_version: impression.wtype_version,
    dn_ad_type: impression.ad_type,
    dn_video_ad_type: impression.video_ad_type,
    dn_video_total_duration: impression.video_total_duration,
    dn_video_view_duration: impression.video_view_duration,
    event_type: '',
    event_name: '',
  };

  return event;
};

export const getBannerData = (impression: Impression) => {
  const data = {
    bannerPageType: impression?.namespace0,
    bannerPageData: impression?.pageData2,
    bannerPageSection: impression?.namespace1,
    bannerPosition: impression?.position2,
    bannerTitle: impression?.namespace2,
    bannerTransactionId: impression?.transaction_id,
    bannerBrandId: impression?.brand_ids?.join(','),
  };

  const bannerDetailsValues = [
    data.bannerPageType,
    data.bannerPageData,
    data.bannerPageSection,
    data.bannerPosition,
    data.bannerTitle,
    data.bannerTransactionId,
    data.bannerBrandId,
  ];

  return {
    ...data,
    bannerDetails: bannerDetailsValues.join('|'),
  };
};

const trackBannerClick = (impresssion: Impression) => {
  NFA.track({
    event: NLP_EVENTS.BANNER_CLICKED,
    data: getBannerData(impresssion),
  });
};

export const getClickEventData = (impression: Impression) => {
  trackBannerClick(impression);

  if (impression.event_name === EVENT_NAMES.PRODUCT_CLICKED) {
    const event: Impression = {
      ...impression,
      hit_recorded_at: impression.timestamp || new Date().getTime() / 1000,
    };
    return event;
  }

  const event: Event = {
    ...getCommonEventData(impression),
    event_type: EVENT_TYPES.CLICK,
    event_name: impression.button_type ? EVENT_NAMES.WIDGET_BUTTON_CLICK : EVENT_NAMES.BANNER_CLICK,
  };

  return event;
};

export const getImpressionEventData = (impression: Impression, impressionType: IMPRESSION_TYPE) => {
  if (impressionType === IMPRESSION_TYPE.PRODUCT) {
    const productImpressionEvent = {
      ...impression,
      event_type: IMPRESSION,
      event_name: impression.button_type
        ? EVENT_NAMES.WIDGET_BUTTON_IMPRESSION
        : EVENT_NAMES.PRODUCT_IMPRESSION,
      hit_recorded_at: impression.timestamp || new Date().getTime() / 1000,
    };
    return productImpressionEvent;
  }

  const event: Event = {
    ...getCommonEventData(impression),
    dn_impressions: impression.impressions || 1,
    event_type: IMPRESSION,
    event_name: EVENT_NAMES.BANNER_IMPRESSION,
    dn_impression_version: impression.event.indexOf('v2') === -1 ? 'v1' : 'v2',
  };

  return event;
};
