// libs
import get from 'lodash/get';

// selectors
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';
import { getSSRExperimentVariant } from '@nykaa/experiment-sdk/store/selectors';

export const getLandingPageExperiments = ({
  pageName,
  state,
}: {
  pageName: string;
  state: any;
}) => {
  // get landing page AB config from remoteConfig
  const landingPageABConfig = getRemoteConfig(state, 'landingPageABConfig');

  const pageExperiments = get(landingPageABConfig, pageName);
  let experiments: { [experimentId: string]: string } = {};

  // if page has no experiments return empty object
  if (pageExperiments === undefined) {
    return experiments;
  }

  pageExperiments.forEach((experimentId: string) => {
    // get the variant for current user for the given experiment
    const { isEligible, variant } = getSSRExperimentVariant(state, experimentId);

    // if not eligible or variant is undefined don't add in experiments object
    if (isEligible === false || !variant) {
      return;
    }

    experiments[experimentId] = variant;
  });

  return experiments;
};
