// libs
import get from 'lodash/get';
import { createSelector } from 'reselect';

// types
import { IWishlistState } from './types';

export const wishlist = (state: { wishlist: IWishlistState }): IWishlistState => state.wishlist;

export const getWishlistIds = createSelector([wishlist], (wishlist_data): string[] =>
  get(wishlist_data, 'wishlistIds', [])
);

export const isProductInWishlist = createSelector(
  [getWishlistIds, (state: any, productId: string) => productId],
  (wishlistIds: string[], productId: string) => {
    return wishlistIds.includes(productId);
  }
);
export const getProducts = createSelector([wishlist], (wishlist_data) =>
  get(wishlist_data, 'products', [])
);

export const getTotalCount = createSelector([wishlist], (wishlist_data) =>
  get(wishlist_data, 'totalCount', -1)
);

export const getTotalProductsFetched = createSelector(
  [wishlist],
  (wishlist_data) => get(wishlist_data, 'products', []).length
);

export const getTotalProductOnPageLoad = createSelector([wishlist], (wishlist_data) =>
  get(wishlist_data, 'totalProductOnPageLoad')
);
export const getPagesFetched = createSelector([wishlist], (wishlist_data) =>
  get(wishlist_data, 'pagesFetched', [])
);

export const getShowAnimationForProductId = createSelector(
  [wishlist],
  (wishlist_data) => wishlist_data.showAnimationForProductId
);

export const isProductFetching = createSelector(
  [wishlist],
  (wishlist_data) => wishlist_data.isProductFetching
);

export const product = (id: string) =>
  createSelector([wishlist], (wishlist_data) =>
    wishlist_data.products.find((productItem) => productItem.productId === id)
  );
export const productFetchError = createSelector(
  [wishlist],
  (wishlist_data) => wishlist_data.productFetchError
);

export const isWishlistUpdating = createSelector(
  [wishlist],
  (wishlist_data) => wishlist_data.isUpdating
);
