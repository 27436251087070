/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { ILandingpageState } from './types';

export const app = (state: { landingpage: ILandingpageState }): ILandingpageState =>
  state.landingpage;

export const getLandingPageData = createSelector([app], (data): object => data.landingpageData);

export const isFetching = createSelector([app], (data): boolean => data.isFetching);

export const isFetchingError = createSelector([app], (data): boolean => data.isFetchingError);

export const getErrorStatusCode = createSelector([app], (data): string => data.errorStatusCode);

export const isNotFound = createSelector([app], (data): boolean => data.isNotFound);
