// helpers
import { ApiHelper } from 'shared/helpers/api';
import { getAdPlatformURL, ROUTES } from 'shared/api/urls';
import { getDeviceType } from 'shared/components/ResponsiveLoader/helpers';

// constants
import { RENDER_MODE } from 'shared/constants';

const getPlatformIdentifier = () => {
  const serverRenderMode = getDeviceType();

  return serverRenderMode === RENDER_MODE.DESKTOP ? 'dweb' : 'mweb';
};

export interface LandingPageProps {
  pageName: string;
  headers: Record<string, string>;
  experiments?: Record<string, string>;
  pageData?: string;
}

export const getLandingPageData = ({
  headers,
  pageName,
  experiments = {},
  pageData,
}: LandingPageProps): Promise<object> => {
  const baseUrl = getAdPlatformURL(ROUTES.AD_PLATFORM_API);

  const params = new URLSearchParams();
  params.append('page_type', pageName);
  params.append('page_section', '*');
  params.append('platform', getPlatformIdentifier());

  Object.keys(experiments).forEach((experimentId) => {
    params.append(experimentId, experiments[experimentId]);
  });

  if (pageData) {
    params.append('page_data', pageData);
  }

  const url = `${baseUrl}?${params.toString()}`;
  return ApiHelper(url, { headers }).then(async (response: Response) => {
    if (response.ok) {
      try {
        return await response.json();
      } catch {
        return Promise.reject();
      }
    }
    return Promise.reject();
  });
};
