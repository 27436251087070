// types
import { Event } from './types';

export const sendSingleEvent = (event: Record<string, any>) => {
  if (window && window.na && event?.event_name) {
    window.na.sendEvent(event?.event_name, event);
  }
};

export const sendMultipleEvents = (events: Event[]) => {
  if (window && window.na && events.length > 0) {
    window.na.sendMultipleEvents(events);
  }
};

export const sendImpressionEvent = (event: Event) => {
  if (window && window.na && event?.event_name) {
    window.na.sendImpressionEvent(event.event_name, event);
  }
};
