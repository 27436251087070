// libs
import React, { useState, memo, useCallback } from 'react';

// global components
import Portal from 'shared/components/Portal';

// styles
import { ScrollToTopButton, Observer, ScrollToTopIcon } from './styles';

// defs
interface IScrollToTopProps {
  className?: string;
  iconClassName?: string;
  IOClassName?: string;
  children?: React.ReactNode;
  doAnimation?: boolean;
  iconSize?: string;
}

const SCROLL_TOP_ICON = '20px';

const ScrollToTop = ({
  className = '',
  iconClassName = '',
  IOClassName = '',
  children,
  doAnimation = true,
  iconSize = SCROLL_TOP_ICON,
}: IScrollToTopProps) => {
  const [showScroll, setShowScroll] = useState(false);

  const scrollToTop = useCallback(() => {
    window.requestAnimationFrame(() => {
      // If page bottom is too away straight away scroll to top
      if (doAnimation && window.pageYOffset < window.innerHeight * 25) {
        var $el = document.getElementById('app');
        $el && $el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        window.scrollTo(0, 0);
      }
    });
  }, [doAnimation]);

  const onVisible = useCallback(() => setShowScroll(false), []);
  const onHidden = useCallback(() => setShowScroll(true), []);

  return (
    <Portal>
      <Observer
        onHidden={onHidden}
        onVisible={onVisible}
        data-test="io-observer"
        className={IOClassName}
      />
      {showScroll && (
        <ScrollToTopButton
          as="button"
          aria-label="scroll-up"
          onClick={scrollToTop}
          data-test="scrollButton"
          className={className}
        >
          {children !== undefined && children}
          <span className={iconClassName} data-test={'scrl-icon'}>
            <ScrollToTopIcon size={iconSize} />
          </span>
        </ScrollToTopButton>
      )}
    </Portal>
  );
};

export default memo(ScrollToTop);
